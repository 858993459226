<script setup lang="ts">
import Logo from '@mui/assets/logos/medmastery-color.svg?component';

const config = useRuntimeConfig();
</script>

<template>
  <div class="bg-neutral-5 lg:bg-white flex flex-col h-dvh">
    <header class="lg:fixed flex justify-center top-0 lg:bg-transparent bg-white lg:p-8 pt-2.5 pb-1.5">
      <a :href="config.public.publicWebsite">
        <Logo />
      </a>
    </header>

    <slot />
  </div>
</template>

<style lang="scss">
.page {
  height: 100%;
}
</style>
